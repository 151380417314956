<script setup>
import carousal from '@/components/carousel.vue';
import ratioContainer from '@/components/ratio-container.vue';
import testimonial from '@/components/testimonial.vue';
import youtubeIcon from '@/components/youtube-icon.vue';
import { SwiperSlide } from 'swiper/vue';
import {
  imageError,
  kebabCase,
  convertEmbedToURL,
  convertEmbedVideoToImage,
  resizeImage680
} from '~/scripts';
import { inject, ref, onMounted, computed } from 'vue';
const vHref = inject('vHref');
const previewMode = inject('previewMode');
const openModal = inject('openModal');
const vEmbedDraggable = inject('vEmbedDraggable');
const vScrollOverEvent = inject('vScrollOverEvent');
const props = defineProps({
  bodyContentHandler: { type: Object, default: () => {} }
});
const titleStyling = `section-title text-center w-4/12 md:w-full max-w-screen-xl text-3xl text-gray-600`;
const sectionStyling = `flex items-center w-full flex-col`;

const {
  filterVideosByKey,
  filterGalleryByKey,
  hideControls,
  getKeyFromTestimonial
} = props.bodyContentHandler;
const videoRatio = ref('300px');

onMounted(() => {
  videoRatio.value = '100%';
});
const additionalInformationSansAboutMe = computed(
  () => props.bodyContentHandler.additionalInformationSansAboutMe
);
</script>
<template>
  <div
    class="extra-info body-content-section flex flex-col items-center justify-center w-full mb-20"
  >
    <section
      v-for="info of additionalInformationSansAboutMe"
      v-scroll-over-event
      class="section"
      :analyticsTitle="info.key"
      :title="info.title"
      :class="[
        sectionStyling,
        `${kebabCase(info.type)}-section`,
        info.key,
        `${info.key}-section`
      ]"
      :key="info.id"
    >
      <h2
        :class="[titleStyling, `${kebabCase(info.type)}-title`]"
        data-nosnippet="false"
      >
        {{ info.title }}
      </h2>
      <carousal
        v-if="
          info.type == 'testimonials' && bodyContentHandler.testimonials?.length
        "
        class="testimonials w-11/12"
        :class="info.key"
        :slidesPerView="4"
        :hideControls="hideControls(bodyContentHandler.testimonials)"
      >
        <swiper-slide
          v-for="(review, i) of bodyContentHandler.testimonials"
          :key="i"
        >
          <div class="pr-2 w-full">
            <testimonial
              :is="testimonial"
              :value="review"
              @click="
                () =>
                  openModal(
                    getKeyFromTestimonial(review),
                    'testimonial',
                    review.title
                  )
              "
            />
          </div>
        </swiper-slide>
      </carousal>
      <carousal
        v-else-if="
          info.type == 'imageGallery' && filterGalleryByKey(info.key).length
        "
        class="image-gallery w-11/12"
        :hideControls="hideControls(filterGalleryByKey(info.key))"
      >
        <swiper-slide
          v-for="(image, i) of filterGalleryByKey(info.key)"
          class="errorBreak"
          :key="i"
        >
          <a
            rel="nofollow"
            class="image-gallery-link w-full flex items-center"
            :title="image.title"
            :analyticsTitle="image.title"
            type="image"
            v-href="image.link"
          >
            <img
              :src="resizeImage680(image.url)"
              loading="lazy"
              class="image-gallery-image w-full"
              @error="imageError"
            />
          </a>
        </swiper-slide>
      </carousal>
      <carousal
        v-else-if="
          info.type == 'videoGallery' && filterVideosByKey(info.key).length
        "
        class="video-gallery w-11/12"
        :hideControls="hideControls(filterVideosByKey(info.key))"
      >
        <swiper-slide
          v-for="(video, i) of filterVideosByKey(info.key)"
          :key="i"
        >
          <ratio-container :width="videoRatio" :ratio="0.56">
            <youtube-icon
              class="video-gallery-video h-full w-full overflow-hidden cursor-pointer thumbnail-size"
              v-if="previewMode"
            >
              <img
                :src="convertEmbedVideoToImage(convertEmbedToURL(video.embed))"
                :alt="video.title"
              />
            </youtube-icon>
            <div
              v-else
              v-embed-draggable="video.embed"
              :analyticsTitle="video.title"
              :title="video.title"
              class="video-gallery-video h-full w-full overflow-hidden cursor-pointer"
            />
          </ratio-container>
        </swiper-slide>
      </carousal>
      <div
        v-else-if="info.description"
        v-html="info.description"
        class="about-me-mobile flex justify-center flex-col my-8 w-11/12"
      ></div>
    </section>
  </div>
</template>
