/* eslint-disable no-unused-vars */
import { forEach, includes, split } from 'ramda';
import { $vfm } from 'vue-final-modal';
import YTPlayer from 'yt-player';
import { formatLink } from '.';

export const vHref = (captureEvent) => (el, bind) => {
  if (el && bind.value) {
    if (
      includes('modal:', bind.value) ||
      el.attributes?.type?.value == 'modal'
    ) {
      el.addEventListener('click', (e) => {
        e.preventDefault();
        const value = includes('modal:', bind.value)
          ? bind.value.split('modal:')[1]
          : bind.value;
        $vfm.show(value);
        captureEvent({
          action: 'open',
          type: value == 'lead-form' ? value : 'modal',
          value,
          title: el.attributes?.analyticsTitle?.value
        });
      });
    } else {
      el.href = formatLink(bind.value, el.attributes?.type?.value);
      el.target = '_blank';
      el.addEventListener('click', () => {
        captureEvent({
          action: 'click',
          type: el.attributes?.type?.value || 'url',
          value: bind.value,
          title: el.attributes?.analyticsTitle?.value
        });
      });
    }

    el.className += ' cursor-pointer';
  }
};

export const vEmbedDraggable = (captureEvent) => ({
  mounted: (el, bind) => {
    if (!el || !bind.value) return;
    const inner = document.createElement('div');
    el.appendChild(inner);
    const iframeHolder = document.createElement('div');
    iframeHolder.innerHTML = bind.value;
    const iframe = iframeHolder.firstChild;
    const src = iframe.src;
    const width = iframe.width;
    const height = iframe.height;
    const fullscreen = true;
    const controls = false;
    const videoId = split('https://www.youtube.com/embed/', src)[1];
    const player = new YTPlayer(inner, [
      { width, height, fullscreen, controls }
    ]);
    player.load(videoId);
    el.firstChild.classList.add('pointer-events-none');
    el.firstChild.classList.add('h-full');
    player.on('paused', () => {
      el.firstChild.classList.add('pointer-events-none');
    });
    el.addEventListener('click', () => {
      el.firstChild.classList.remove('pointer-events-none');
      player.play();
      captureEvent({
        action: 'view-media',
        type: 'video',
        value: src,
        title: el.attributes?.analyticsTitle?.value
      });
    });
  }
});

const getScrollTop = (offset = 0) =>
  (window.pageYOffset !== undefined
    ? window.pageYOffset
    : (document.documentElement || document.body.parentNode || document.body)
      .scrollTop) + offset;
const getScreenHeight = () =>
  (document.documentElement || document.body.parentNode || document.body)
    .clientHeight || 0;

/**
 * Logic here is if you scroll over something on the way down, it will trigger the analytics.
 * To scroll over something it should be at about half way over the screen.
 */
export const vScrollOverEvent = (captureEvent) => {
  const elementWatchers = [];
  const onScrollOverEvent = (scrollTopStart, scrollTopEnd) => (watcher) => {
    if (document.body.clientWidth >= 768)
      if (
        (watcher.element.offsetTop >= scrollTopStart &&
          watcher.element.offsetTop + watcher.element.clientHeight <=
          scrollTopEnd) ||
        (watcher.element.offsetTop < scrollTopStart &&
          watcher.element.offsetTop + watcher.element.clientHeight >
          scrollTopEnd)
      ) {
        if (!watcher.passFlag) {
          watcher.passFlag = true;
          captureEvent({
            action: 'scroll',
            type: 'section',
            value: '',
            title: watcher.element.attributes?.analyticsTitle?.value
          });
        }
      }
  };
  window.addEventListener('scroll', () =>
    forEach(
      onScrollOverEvent(getScrollTop(), getScrollTop(getScreenHeight())),
      elementWatchers
    )
  );
  return {
    mounted: (element) => {
      elementWatchers.push({ passFlag: false, element });
    }
  };
};

export default { vHref, vEmbedDraggable, vScrollOverEvent };
