<script setup>
import accordion from '@/components/accordion.vue';
import { SwiperSlide } from 'swiper/vue';
import carousal from '@/components/carousel.vue';
import aboutMeSectionContent from '@/components/about-me-section/about-me-section-content.vue';
import ratioContainer from '@/components/ratio-container.vue';
import testimonial from '@/components/testimonial.vue';
import youtubeIcon from '@/components/youtube-icon.vue';
import QRCodeStyling from 'qr-code-styling';
import { inject, reactive, ref, computed, onMounted, watch } from 'vue';
import {
  imageError,
  kebabCase,
  convertEmbedToURL,
  convertEmbedVideoToImage,
  resizeImage680
} from '~/scripts';
import { map, F } from 'ramda';
const qrCode = ref();
const qrCodeRef = ref();
const previewMode = inject('previewMode');
const vHref = inject('vHref');
const openModal = inject('openModal');
const captureEvent = inject('captureEvent');
const vEmbedDraggable = inject('vEmbedDraggable');
const props = defineProps({
  bodyContentHandler: { type: Object, default: () => {} },
  defaultOpen: { type: Number, default: -1 },
  exclusiveOpen: { type: Boolean, default: false },
  leadFormHandler: {
    type: Object,
    default: () => {}
  }
});
const qrCodeAccordion = ref(false);

const collapseStyling = `remove-padding bio-collapse`;

const {
  filterVideosByKey,
  filterGalleryByKey,
  getDropdownGroup,
  hideControls,
  getKeyFromTestimonial
} = props.bodyContentHandler;
const accordionMap = reactive(
  map(F, props.bodyContentHandler.additionalInformation)
);
accordionMap[props.defaultOpen] = true;
const accordionUpdate = (index) => {
  if (props.exclusiveOpen) {
    const temp = accordionMap[index];
    accordionMap.fill(false);
    accordionMap[index] = temp;
  }
};
const marginBot = (index, length, margin = 'mb-8') => {
  return { [margin]: index != length - 1 };
};

const additionalInformation = computed(
  () => props.bodyContentHandler.additionalInformation
);
const qrCodeOptions = computed(() => props.bodyContentHandler.qrCodeOptions);
const generateQrCode = () => {
  if (!qrCode.value) {
    qrCode.value = new QRCodeStyling(qrCodeOptions.value);
    qrCode.value.append(qrCodeRef.value);
  } else {
    qrCode.value.update(qrCodeOptions.value);
  }
  if (!qrCodeRef.value) {
    console.warn('QR Code DOM Element Missing');
    return;
  }
};
onMounted(generateQrCode);
watch(qrCodeOptions, generateQrCode);
</script>
<template>
  <div class="bio-collapse-container flex flex-col">
    <accordion
      v-for="(info, i) of additionalInformation"
      :header-icon="iconStyle + ' ' + info.icon"
      :loadIndex="i"
      :class="[
        collapseStyling,
        `${kebabCase(info.type)}-dropdown`,
        `${info.key}-dropdown`
      ]"
      @triggerAnalytics="
        captureEvent({
          value: '',
          type: 'dropdown',
          action: 'open',
          title: info.title
        })
      "
      :key="info.id"
      v-model="accordionMap[i]"
      @update="accordionUpdate(i)"
    >
      <template v-slot:head>
        <h2 class="bio-collapse-head-title" data-nosnippet="false">{{
          info.title
        }}</h2>
      </template>
      <template v-slot:body>
        <about-me-section-content
          v-if="info.type == 'aboutme'"
          :description="bodyContentHandler.aboutMe.description"
          :aboutMeVideo="bodyContentHandler.aboutMe.aboutMeVideo"
          :leadFormHandler="leadFormHandler"
        />
        <carousal
          v-else-if="info.type == 'testimonials'"
          class="testimonials"
          :slidesPerView="1"
          :hideControls="hideControls(bodyContentHandler.testimonials, 4)"
        >
          <swiper-slide
            v-for="(reviews, i) of getDropdownGroup(
              bodyContentHandler.testimonials
            )"
            :key="i"
          >
            <div class="flex flex-col w-full">
              <testimonial
                class="pl-8"
                :class="marginBot(j, reviews.length)"
                v-for="(review, j) of reviews"
                @click="
                  () =>
                    openModal(
                      getKeyFromTestimonial(review),
                      'testimonial',
                      review.title
                    )
                "
                :value="review"
                :key="j"
              />
            </div>
          </swiper-slide>
        </carousal>
        <carousal
          v-else-if="info.type == 'imageGallery'"
          class="image-gallery"
          :slidesPerView="1"
          :hideControls="hideControls(filterGalleryByKey(info.key), 6)"
        >
          <swiper-slide
            v-for="(images, i) of getDropdownGroup(
              filterGalleryByKey(info.key),
              6
            )"
            :key="i"
          >
            <div class="flex flex-col">
              <a
                rel="nofollow"
                v-for="(image, j) of images"
                :key="j"
                :title="image.title"
                :analyticsTitle="image.title"
                :class="marginBot(j, images.length)"
                type="image"
                class="image-gallery-link w-full errorBreak"
                v-href="image.link"
              >
                <img
                  :src="resizeImage680(image.url)"
                  loading="lazy"
                  class="image-gallery-image w-full"
                  @error="imageError"
                />
              </a>
            </div>
          </swiper-slide>
        </carousal>
        <carousal
          v-else-if="info.type == 'videoGallery'"
          class="video-gallery"
          :slidesPerView="1"
          :hideControls="hideControls(filterVideosByKey(info.key), 4)"
          :spaceBetween="30"
        >
          <swiper-slide
            v-for="(videos, i) of getDropdownGroup(filterVideosByKey(info.key))"
            :key="i"
          >
            <div class="flex flex-col w-full">
              <ratio-container
                width="100%"
                :ratio="0.56"
                :key="j"
                v-for="(video, j) of videos"
                :class="marginBot(j, videos.length)"
              >
                <youtube-icon
                  class="video-gallery-video h-full w-full overflow-hidden cursor-pointer thumbnail-size"
                  v-if="previewMode"
                >
                  <img
                    :src="
                      convertEmbedVideoToImage(convertEmbedToURL(video.embed))
                    "
                    :alt="video.title"
                  />
                </youtube-icon>
                <div
                  v-else
                  v-embed-draggable="video.embed"
                  :title="video.title"
                  :analyticsTitle="video.title"
                  class="video-gallery-video h-full w-full overflow-hidden cursor-pointer"
                />
              </ratio-container>
            </div>
          </swiper-slide>
        </carousal>
        <about-me-section-content
          v-else-if="info.description"
          :description="info.description"
          :leadFormHandler="leadFormHandler"
        />
      </template>
    </accordion>
    <accordion
      v-model="qrCodeAccordion"
      :header-icon="bodyContentHandler.iconStyle + ' fal fa-qrcode'"
      class="errorBreak"
      :aliveOnLoad="true"
      :class="collapseStyling"
      @triggerAnalytics="
        captureEvent({
          value: '',
          type: 'qr_code',
          action: 'view-media',
          title: 'Scan my QR code'
        })
      "
    >
      <template v-slot:head>
        <h2>
          <span class="personal-qrcode bio-collapse-head-title"
            >Scan my <span class="normal-case">QR</span> code</span
          >
        </h2>
      </template>
      <template v-slot:body>
        <img
          v-if="bodyContentHandler.qrCodeUrl"
          :src="bodyContentHandler.qrCodeUrl"
          loading="lazy"
          alt="qrcode"
          class="w-full"
          @error="imageError"
        />
        <div
          v-show="!bodyContentHandler.qrCodeUrl"
          ref="qrCodeRef"
          class="flex justify-center dynamic-qr-code"
        ></div>
      </template>
    </accordion>
  </div>
</template>
<style>
.dynamic-qr-code canvas {
  width: 100% !important;
}
</style>
